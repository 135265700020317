window.dataLayer = window.dataLayer || [];

function gtag() {
  dataLayer.push(arguments);
}
gtag('js', new Date());

gtag('config', ga4tag);


if (gaEcommerce) {
  if (gaRevenue.length > 0 && gaOrderId.length > 0) {
    gtag("event", "purchase", {
      transaction_id: gaOrderId,
      affiliation: "",
      value: gaRevenue,
      currency: "USD",
      items: [{
        item_id: "1",
        item_name: "Products"
      }]
    });
  }
}